import { FlexSpreadList } from "../components/Helper/HelperComponents";

const SeasonalPage = () => {
  const names = ["Nando", "Timon", "Lauro"];
  const now = new Date();
  return (
    <FlexSpreadList>
      {now.getMonth() == 11 ? (
        <>
          <h1 style={{ textAlign: "center", fontSize: 40 }}>
            {now.getDate()}. Dezember
          </h1>
          <h1 style={{ textAlign: "center", fontSize: 200 }}>
            {names[now.getDate() % 3]}
          </h1>
        </>
      ) : (
        <h1 style={{ textAlign: "center", fontSize: 100 }}>Nonig Ufmache!</h1>
      )}
    </FlexSpreadList>
  );
};

export default SeasonalPage;
